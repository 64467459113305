/******************* Map ****************************/

/* all map containers must be full height */
#map {
  height: 100%;
  width: 100%;
  }

#map-canvas {
  width: 100%;
  height: 100%;
  padding:0px;
  overflow: hidden !important;
}
#map-canvas{
  min-height: 500px;
}
.company-location {
  position: absolute;
  left: 50%;

  top: 100px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 3;
  padding: 15px 0px;
  border-radius: 1px 0 1px 1px;
  color: #FFF;
  width: 200px;
  h4, p {color:#fff !important; margin-bottom:0;text-align: center !important;}
}

h4.company-location {
  color:#fff !important;
}


// #map {
//   height: 100%;
//   width: 100%;
//   }
//
// #map-canvas {
//   width: 100%;
//   height: 500px;
//   padding:0px;
//   overflow:visible !important;
// }
//
// .company-location {
//   position: relative;
//   float: right;
//   top: 100px;
//   background-color: rgba(0, 0, 0, 0.75);
//   z-index: 3;
//   padding: 15px 0px;
//   border-radius: 1px 0 1px 1px;
//   color: #FFF;
//   width: 200px;
//   h4, p {color:#fff !important; margin-bottom:0;text-align: center !important;}
// }
//
// h4.company-location {
//   color:#fff !important;
// }
